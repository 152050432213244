import React from "react";

export default function Banner({ url }) {
  return (
    <div
      className="h-56 w-full bg-center"
      style={{ backgroundImage: `url(${url})` }}
    ></div>
  );
}

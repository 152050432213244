import React from "react";
import { Link } from "gatsby";

export default function NavigationBar() {
  return (
    <nav className="bg-white shadow-lg relative">
      <div className="container">
        <ul className="flex justify-end text-gray-900">
          <li className="px-5 py-5">
            <Link to="/">Home</Link>
          </li>
          <li className="px-5 py-5">
            <Link to="/posts">Blog</Link>
          </li>
        </ul>
      </div>
    </nav>
  );
}

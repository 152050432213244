import Banner from "./Banner";
import NavigationBar from "../navigation/NavigationBar";
import React from "react";
import bannerImage from "../../assets/bannerImage.jpg";

export default function DefaultLayout({ children }) {
  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex flex-col flex-1">
        <NavigationBar />
        {children}
      </div>

      <Banner url={bannerImage} />
    </div>
  );
}
